import React from "react";
import Seo from "../components/shared/seo";
import Hero from "../components/cookies/hero";

function Cookies() {
  return (
    <>
      <Seo
        title="Cookie Policy - PDFReader.com"
        special={true}
        noRobotsIndex={true}
      />
      <Hero />
    </>
  );
}

export default Cookies;
