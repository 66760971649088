import React from "react";
import styled from "styled-components";
import PositionContainer from "../shared/container";
import MainTitle from "../shared/heroTitles";
import Paragraph from "../shared/paragraphs";
import { Link } from "gatsby";
import Table from "../shared/table";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
`;

const ContentHolder = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  gap: 30px;
`;

const TitleBox = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 540px;

  @media (max-width: 560px) {
    width: 100%;
  }
`;

const SubtitleContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  width: 499px;

  @media (max-width: 560px) {
    width: 100%;
  }
`;

const RulesBox = styled.div`
  display: flex;
  flex-direction: column;
  width: 792px;
  gap: 40px;
  margin-bottom: 90px;
  box-sizing: border-box;

  @media (max-width: 800px) {
    width: 100%;
  }
`;

const TermsTitles = styled.div`
  color: #111111;
  font-size: 20px;
  line-height: 27px;
  font-weight: bold;
  font-style: normal;
`;

const Box = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
  box-sizing: border-box;
  word-break: break-word;
`;

const Title = styled.h2`
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 27px;
  text-align: center;
  color: #111111;
  margin: 0px;
  padding: 0px;
`;

function Hero() {
  return (
    <Container>
      <PositionContainer>
        <ContentHolder>
          <TitleBox>
            <MainTitle features={true}>Cookie Policy</MainTitle>
          </TitleBox>
          <SubtitleContent>
            <Title terms={true}>PDFREADER.COM COOKIE POLICY</Title>
            <Paragraph type="center">Last update: October 4, 2022</Paragraph>
          </SubtitleContent>
          <RulesBox>
            <Box>
              <Paragraph>
                This policy contains full information about the cookies that we
                use on Pdfreader.com (hereinafter referred to as the "Site") and
                the tools and knowledge to manage them. We are continuously
                making further improvements and changes to the ways we use
                cookies and we will keep this page updated with the cookies we
                use. Please refer also to our{" "}
                <Link to="/privacy/">Privacy Policy</Link>.
              </Paragraph>
            </Box>
            <Box>
              <TermsTitles>What are cookies?</TermsTitles>
              <Paragraph>
                Cookies are small text files that the websites visited by users
                send through to their terminals (computers, tablets,
                smartphones, notebooks, etc.), usually to the browser, where
                they are stored to then be retransmitted to the same websites
                when the same users subsequently revisit the sites.
              </Paragraph>
              <Paragraph>
                Please note that cookies will not damage your device.
              </Paragraph>
            </Box>
            <Box>
              <TermsTitles>Cookie categories</TermsTitles>
              <TermsTitles>Technical cookies</TermsTitles>
              <TermsTitles>Strictly necessary cookies</TermsTitles>
              <Paragraph>
                These cookies are essential for you to move around the Site, to
                use its features and provide the services you have requested,
                such as access to restricted areas of the website.
              </Paragraph>
              <Paragraph>
                They can, for example, memorize previous actions (e.g., entered
                text, etc.) when navigating back to a page in the same session,
                manage security tokens for different services within the Site to
                identify the status of the visitor (e.g., registered or
                unregistered), etc.
              </Paragraph>
              <Paragraph>
                <u>
                  Without these cookies we cannot provide the services you
                  request (such as, shopping cart, e-billing, etc.).
                </u>
              </Paragraph>
            </Box>
            <Box>
              <TermsTitles>Performance cookies</TermsTitles>
              <Paragraph>
                These cookies can be session or persistent, and their use is
                limited to the performance and improvement of the Site features,
                such as collecting information on how a visitor uses the Site.
              </Paragraph>
              <Paragraph>
                They can do, for example, web analytics, error handling, test
                different website page structures, etc.
              </Paragraph>
              <Paragraph>
                <u>
                  These cookies do not collect information that can identify
                  you, as all information collected by them are aggregated in an
                  anonymous form and are only used to improve the functionality
                  of the Site.
                </u>
              </Paragraph>
            </Box>
            <Box>
              <TermsTitles>Functional cookies</TermsTitles>
              <Paragraph>
                These cookies can be session or persistent, and are usually the
                result of a user action, but they can also be implemented when
                providing a service not specifically requested but simply
                offered to users.
              </Paragraph>
              <Paragraph>
                They can, for example, remember the settings that a user has
                applied to a website (such as layout, font size, preferences,
                colors, etc.), remember a choice so that users are no longer
                requested to fill out a questionnaire, detect if a service has
                already been offered, provide information to allow access to an
                optional service (such as the offer of a live chat session),
                fulfil a user request (such as submitting a comment).
              </Paragraph>
            </Box>
            <Box>
              <TermsTitles>Profiling cookies</TermsTitles>
              <TermsTitles>
                Third-party profiling cookies for marketing / retargeting
                purposes
              </TermsTitles>
              <Paragraph>
                These cookies are used by our partners, upon your prior consent,
                in order to profile users for advertising and retargeting
                purposes.
              </Paragraph>
              <Paragraph>
                They can, for example, create user profiles to serve ads that
                are in line with your preferences showed as you surf the web;
                record your choices and activity on our website to carry out
                statistical analysis – for us or for third parties – by tracking
                your preferences and browsing behavior, serve customized ads to
                you, integrate some common feature of the major social media and
                provide it within the site, etc.
              </Paragraph>
            </Box>
            <Box>
              <TermsTitles>Cookies used on this Site</TermsTitles>
              <Table type={false}>
                <thead>
                  <tr>
                    <th className="one">Cookies</th>
                    <th>Type</th>
                    <th>Source</th>
                    <th>Purpose</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td className="one">
                      Google analytics cookies (_ga, _gid, _gat,AMP_TOKEN)
                    </td>
                    <td>Profiling</td>
                    <td>Google Analytics</td>
                    <td>
                      These cookies are used by our partners in order to profile
                      users for advertising and retargeting purposes. They can,
                      for example, create user profiles to serve ads that are in
                      line with your preferences showed as you surf the web;
                      record your choices and activity on our website to carry
                      out statistical analysis – for us or for third parties –
                      by tracking your preferences and browsing behavior, serve
                      customized ads to you, integrate some common feature of
                      the major social media and provide it within the site,
                      etc. For more information on these cookies, please click
                      here{" "}
                      <a href="https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage?hl=en">
                        {" "}
                        https://developers.google.com/analytics/devguides/collection/analyticsjs/cookie-usage?hl=en
                      </a>{" "}
                      or if you want to opt-out click here{" "}
                      <a href="https://tools.google.com/dlpage/gaoptout">
                        https://tools.google.com/dlpage/gaoptout
                      </a>
                    </td>
                  </tr>
                  <tr>
                    <td className="one">
                      Internal campaign performance cookie (url)
                    </td>
                    <td>Performance</td>
                    <td>Pdfreader.com</td>
                    <td>
                      Campaign tracking by using anonymous data to see product
                      performance on the market, placement of coupons and
                      special offers, placement of unique anonymous id for in
                      app links.
                    </td>
                  </tr>
                  <tr>
                    <td className="one">Consent cookie (_consent)</td>
                    <td>Functional</td>
                    <td>Pdfreader.com</td>
                    <td>
                      This cookie is created in the event that the user has
                      accepted our standard cookie terms and it lasts for 1
                      year.
                    </td>
                  </tr>
                </tbody>
              </Table>
            </Box>
            <Box>
              <TermsTitles>How can I disable Cookies?</TermsTitles>
              <Paragraph>
                We remind you that, in compliance with current legislation on
                cookies, your prior consent is not necessary for technical
                cookies.
              </Paragraph>
              <Paragraph>
                Anyway, if you do not agree with the use of all or any cookie,
                you must configure the browser settings and disable the cookies
                or stop using the Site. By disabling cookies, however, the Site
                or some of its features may not work properly.
              </Paragraph>
              <Paragraph>
                To modify the use of cookies and block/delete them on the
                device, simply enter the browser settings.
              </Paragraph>
              <Paragraph>
                Configurations of the cookies may be different in each browser,
                even though the steps are very similar. For more details on the
                procedure, visit{" "}
                <a href="http://www.aboutcookies.org">
                  http://www.aboutcookies.org
                </a>{" "}
                or see the “Help” section of your specific browser.
              </Paragraph>
              <Paragraph>
                To learn more about cookies and to manage your preferences on
                third-party cookies please visit{" "}
                <a href="http://www.youronlinechoices.com">
                  http://www.youronlinechoices.com
                </a>
              </Paragraph>
            </Box>
          </RulesBox>
        </ContentHolder>
      </PositionContainer>
    </Container>
  );
}

export default Hero;
